<template>
      <div class="max-w-2xl mx-auto text-center pb-16">
        <h2 class="text-3xl font-bold mb-4">Let’s Connect</h2>
        <p class="text-gray-500 mb-8 md:text-md text-sm">
          Let's connect and collaborate to bring innovative solutions to life.
          Reach out to us and let's build something amazing together!
        </p>
        <router-link class="primary-btn inline-block" to="/contact-us">
          Contact Us
        </router-link>
      </div>
</template>
